<script lang="ts" setup>
const props = withDefaults(defineProps<{
  num: number
  delay?: number
  size?: string
}>(), {
  num: 0,
  delay: 1,
  size: 'small',
})
const { size, delay, num } = toRefs(props)
const showAnimate = ref(true)
const scrollStyle = computed(() => {
  const sizeMap = {
    small: '26px',
    medium: '36px',
    large: '56px',
  }[size.value]
  return {
    '--num': num.value, // CSS变量，提供到style使用
    '--delay': delay.value,
    '--width': sizeMap,
  }
})
watch(showAnimate, () => {
  if (!showAnimate.value) {
    setTimeout(() => {
      showAnimate.value = true
    }, 2000)
  }
})
</script>

<template>
  <div class="scroll-num font-d-din-700" :style="scrollStyle" @animationend="showAnimate = false">
    <ul class="scroll-num__box" :class="{ animate: showAnimate }">
      <li>0</li>
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
      <li>7</li>
      <li>8</li>
      <li>9</li>
      <li>0</li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@keyframes move {
  0% {
    transform: translateY(-90%);
    filter: blur(2px);
  }
  100% {
    transform: translateY(1%);
    filter: blur(2px);
  }
}

@keyframes num-bounce {
  0% {transform:translateY(calc(var(--num) * -9.09% - 7%));filter:none;}
  25% {transform:translateY(calc(var(--num) * -9.09% + 3%));}
  50% {transform:translateY(calc(var(--num) * -9.09% - 1%));}
  70% {transform:translateY(calc(var(--num) * -9.09% + 0.6%));}
  85% {transform:translateY(calc(var(--num) * -9.09% - 0.3%));}
  100% {transform:translateY(calc(var(--num) * -9.09%));filter:none;}
}

.scroll-num {
  // width: var(--width, 20px);
  height: calc(var(--width, 20px) * 1.8);
  color: var(--color-2);
  font-size: 56px;
  // font-size: calc(var(--width, 20px) * 1.1);
  line-height: calc(var(--width, 20px) * 1.8);
  text-align: center;
  overflow: hidden;
  flex-shrink: 0; /* 保证不被压缩 */
  &__box {
    padding: 0;
    margin: 0;
    list-style: none;
    transform: translateY(calc(var(--num) * -9.09%));
  }
  .animate {
    animation: move 1s linear infinite, num-bounce 1s calc(var(--delay) * 1s) forwards;
    /* forwards:停在动画的最后一帧 */
  }
}
</style>
