<template>
  <div class="guide-an5 absolute left-0 top-0 h-full min-w-1300px w-full overflow-hidden" />
</template>

<style lang="scss" scoped>
.guide-an5 {
  background: url("@/assets/img/cooperation-big-bg.png") top center no-repeat;
  background-color: var(--bg-color);
  background-size:cover;
}
</style>
