<script setup lang="ts">
// import GuideHeader from './components/GuideHeader.vue'
import Animation4 from '@/components/Index/Animation4.vue'
import Animation5 from '@/components/Index/Animation5.vue'
import ScrollNum from '@/components/Index/ScrollNum.vue'
// import { useLoginDialog } from '@/hooks/useLoginDialog'
// import { useLoginDialogStore } from '@/stores/user'

useSeoMeta({
  title: 'TikTok数据分析平台&服务商-Glodastory',
  keywords: 'glodastory,tiktok数据,tiktok数据分析,tiktok数据分析平台,glodastory官网',
  description: 'Glodastory是TikTok数据分析平台，提供tiktok电商数据分析、tiktok短视频数据分析、tiktok商品数据分析、tiktok达人分析、tiktok小店数据分析等，助力卖家实现高效选品、市场分析、甄选达人等。',
}, {
  tagPriority: 5,
})

// const useStoreLogin = useLoginDialogStore()
const router = useRouter()
const { isLogin, refetch } = useUser()
onMounted(() => {
  refetch()
})
const { show, redirectAfterLogin } = useLoginDialog()

const { data } = await useArticleList({ index: 1, limit: 8 })
const articleList = computed(() => data.value?.data || [])

function goDetail(path = '/analysis/products') {
  router.push({ path })
}
interface SideBarData {
  name: string
  page: number
}
const sideBarData = ref<SideBarData[]>([{
  name: '创意选品',
  page: 2,
}, {
  name: '竞争格局',
  page: 3,
}, {
  name: '使用案例',
  page: 4,
}, {
  name: 'TikTok资讯',
  page: 5,
}, {
  name: '伙伴寄语',
  page: 6,
}])

const { height: windowH } = useWindowSize()
const scrollParams = reactive({
  maxPage: 6,
  page: 1,
  height: 0,
  startTime: 0,
  endTime: 0,
})
const scrollHeight = computed(() => {
  const h = import.meta.server ? 0 : windowH.value
  return (scrollParams.page - 1) * h
})
const animationStyle = computed(() => {
  return `transform: translate(0px, -${scrollHeight.value}px)`
})
// const onousewheel = debounce((event: any) => {
//   const delta = event.detail || (-event.wheelDelta)
//   if (delta > 0 && scrollParams.page < 4) {
//     scrollParams.page++
//     console.log('向上滚动')
//   }
//   if (delta < 0 && scrollParams.page !== 1) {
//     scrollParams.page--
//     console.log(scrollParams.page, '向下滚动')
//   }
// }, 200)
function onousewheel(event: any) {
  const delta = event.detail || (-event.wheelDelta)
  scrollParams.startTime = new Date().getTime()
  if ((scrollParams.endTime - scrollParams.startTime) < -1200) {
    if (delta > 0 && scrollParams.page < scrollParams.maxPage)
      scrollParams.page++
    if (delta < 0 && scrollParams.page !== 1)
      scrollParams.page--

    scrollParams.endTime = new Date().getTime()
  }
  else {
    event.preventDefault()
  }
}
function mousewheel(e: Event) {
  onousewheel(e)
}
function goTop() {
  scrollParams.page = 1
}
// const isShowFix = ref(true)
// function closeFix() {
//   isShowFix.value = false
// }
function showLogin(val?: string) {
  if (!isLogin.value) {
    // if (val) {
    //   useStoreLogin.loginPath = val
    // }
    // setCloseLoginDialogReload()
    redirectAfterLogin('/analysis/products')
    show()
  }
  else {
    goDetail()
  }
}

function onSideBar(row: SideBarData) {
  scrollParams.page = row.page
}
</script>

<template>
  <div class="guide relative h-full w-full overflow-x-auto">
    <Animation4 v-if="scrollParams.page === 4" />
    <Animation5 v-if="scrollParams.page === 6" />
    <div class="relative z-20 h-[100vh] min-w-1300px w-full overflow-hidden">
      <div class="transition-600" :style="animationStyle" @mousewheel="mousewheel">
        <!-- page1 -->
        <div class="h-[100vh] w-full flex items-center justify-center gap-[4.6vw] overflow-hidden">
          <div class="flex flex-col gap-40px">
            <div class="text-[3.75vw] color-[var(--color-1)] font-600">
              TikTok + 亚马逊 + 抖音<br>
              三合一跨域大数据选品平台
            </div>
            <div v-if="!isLogin" class="relative">
              <img class="guide__page-1-open" src="@/assets/img/open.svg" alt="">
              <div class="hover inline-flex cursor-pointer items-center justify-center gap-[0.625vw] rounded-90px bg-[var(--color-1)] px-[1.354vw] text-[1.145vw] color-[var(--bright-color)] leading-[3.333vw]" @click="showLogin('/analysis/products')">
                免费试用
                <img title="3天免费试用" src="@/assets/img/jt-icon.svg" class="w-[1.302vw]" alt="3天免费试用">
              </div>
            </div>
            <div v-else title="立即使用" class="hover h-[3.333vw] w-[11.09vw] flex cursor-pointer items-center justify-center gap-[0.625vw] rounded-90px bg-[var(--color-1)] px-[1.354vw] text-[1.145vw] color-[var(--bright-color)]" @click="goDetail()">
              立即使用
              <img title="立即使用" src="@/assets/img/jt-icon.svg" class="w-[1.302vw]" alt="立即使用">
            </div>
            <div class="flex gap-48px text-[20px] color-[var(--color-1)]">
              <div>
                <div class="font-d-din-500 flex items-center gap-4px text-28px color-[var(--color-2)]">
                  <div class="font-d-din-700 h-56px flex items-center text-56px">
                    <ScrollNum
                      v-for="(item, i) in '15'"
                      :key="i"
                      :num="Number(item)"
                    />
                    00
                  </div>
                  万+
                </div>
                <span class="pt-6px">
                  美区商品数量
                </span>
              </div>
              <div>
                <div class="font-d-din-500 flex items-center gap-4px text-28px color-[var(--color-2)]">
                  <div class="font-d-din-700 h-56px flex items-center text-56px">
                    <ScrollNum
                      v-for="(item, i) in '30'"
                      :key="i"
                      :num="Number(item)"
                    />
                    00
                  </div>
                  万+
                </div>
                <span class="pt-6px">
                  美区达人数量
                </span>
              </div>
              <div>
                <div class="font-d-din-500 flex items-center gap-4px text-28px color-[var(--color-2)]">
                  <div class="font-d-din-700 h-56px flex items-center text-56px">
                    <ScrollNum
                      v-for="(item, i) in '15'"
                      :key="i"
                      :num="Number(item)"
                    />
                  </div>
                  万+
                </div>
                <span class="pt-6px">
                  美区小店数量
                </span>
              </div>
              <div>
                <div class="font-d-din-500 flex items-center gap-4px text-28px color-[var(--color-2)]">
                  <div class="font-d-din-700 h-56px flex items-center text-56px">
                    1
                  </div>
                  小时起
                </div>
                <span class="pt-6px">
                  视频监控频率
                </span>
              </div>
            </div>
          </div>
          <div class="w-40%">
            <img class="w-full" src="@/assets/img/page-1.png" alt="Tik Tok数据分析平台">
          </div>
        </div>

        <!-- page2 -->
        <div class="box-border h-[100vh] w-full overflow-hidden pt-60px" :class="{ 'page-transition': scrollParams.page === 2 }">
          <div class="box-border h-full flex flex-col items-center justify-center pb-70px">
            <div class="text-[1.979vw] color-[var(--color-1)] font-600">
              首创跨平台选品
            </div>
            <div class="pt-[1.2vh] text-[1.041vw] color-[var(--color-1)]">
              全网首个 TikTok+Amazon+抖音 三合一跨域选品平台
            </div>
            <div
              class="hover mt-[1.2vh] flex cursor-pointer items-center gap-10px rounded-90px bg-[var(--color-1)] px-1.354vw py-0.468vw text-[0.833vw] color-[var(--bright-color)]"
              @click="showLogin('/creative')"
            >
              立即体验
              <img src="@/assets/img/jt-icon.svg" title="立即体验" class="w-1.302vw" alt="立即体验">
            </div>
            <div class="py-[2vh] text-[1.458vw] color-[var(--color-1)]">
              <span class="color-[var(--color-1)] font-600">一键对比</span>
              TikTok、Amazon 、抖音的热销商品，发现蓝海机会
            </div>
            <div class="relative w-54vw">
              <img class="w-full" src="@/assets/img/page-2.png" alt="tiktok+Amazon跨平台选品">
              <div class="guide__page-animate-box page2-animate1">
                <span class="text">
                  Amazon爆单商品，TikTok 有没有机会一样爆？
                </span>
              </div>
              <div class="guide__page-animate-box page2-animate2">
                <span class="text">
                  同一畅销商品，TikTok、Amazon、抖音三者差异及相同是什么?
                </span>
              </div>
              <div class="guide__page-animate-box page2-animate3">
                <span class="text">
                  Amazon推荐视频、抖音带货创意，TikTok 能否借鉴？
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- page3 -->
        <div class="box-border h-[100vh] w-full overflow-hidden pt-64px" :class="{ 'page-transition': scrollParams.page === 3 }">
          <div class="box-border h-full flex flex-col items-center justify-center pb-70px">
            <div class="text-[1.979vw] color-[var(--color-1)] font-600">
              市场竞争格局
            </div>
            <div class="pt-[1.2vh] text-[1.041vw] color-[var(--color-1)]">
              深度解剖2000+细分市场，破解 TikTok 布局难题
            </div>
            <div
              class="hover mt-[1.2vh] flex cursor-pointer items-center gap-10px rounded-90px bg-[var(--color-1)] px-1.354vw py-0.468vw text-[0.833vw] color-[var(--bright-color)]"
              @click="showLogin('/marketing')"
            >
              立即体验
              <img src="@/assets/img/jt-icon.svg" title="立即体验" class="w-1.302vw" alt="立即体验">
            </div>
            <div class="py-[2vh] text-[1.458vw] color-[var(--color-1)]">
              直击市场核心，<span class="color-[var(--color-1)] font-600">大盘、竞争、推广</span>尽在掌握
            </div>

            <div class="relative w-53%">
              <img class="w-full" src="@/assets/img/page-3.png" alt="glodastory tiktok市场分析">
              <div class="guide__page-animate-box page3-animate1">
                <span class="text">
                  细分市场近期趋势表现如何？消费需求是否旺盛？
                </span>
                <br>
              </div>
              <div class="guide__page-animate-box page3-animate2">
                <span class="text">
                  已有哪些玩家在场？市场垄断程度如何？
                </span>
                <br>是否适合新晋卖家？
              </div>
              <div class="guide__page-animate-box page3-animate3">
                <span class="text">
                  高热营销内容是什么？有什么特征？
                </span>
                <br>创作者是谁？
              </div>
            </div>
          </div>
        </div>

        <!-- page4 -->
        <div class="box-border h-[100vh] w-full overflow-hidden pt-64px">
          <div class="box-border h-full flex flex-col items-center justify-center pb-70px">
            <div class="text-[1.979vw] color-[var(--color-1)] font-600">
              优秀使用案例
            </div>
            <div class="pb-3vh pt-[1.2vh] text-[1.041vw] color-[var(--color-1)]">
              他们都在用
            </div>
            <div class="guide__carousel">
              <el-carousel indicator-position="none" arrow="always" autoplay>
                <el-carousel-item>
                  <div class="guide__carousel-box">
                    <div class="guide__carousel-top relative flex gap-14px color-[var(--color-1)]">
                      <img class="h-10.41vw" src="@/assets/img/example-1.png" alt="品牌方">
                      <div class="pr-2.083vw pt-2.083vw text-0.9475vw leading-[1.979vw]">
                        TikTok美区从23年下半年起，赛道进入加速期。2024年官方目标10倍GMV增长，今年美区有非常大的突破机会。Glodastory先聚焦在美区，刚好迎合这波浪潮，把数据分析做透，行业做深。
                      </div>
                      <div class="absolute bottom-1.041vw right-40px text-[1.041vw]">
                        ——原亚马逊美区卖家
                      </div>
                    </div>
                    <div class="guide__carousel-bottom">
                      Glodastory在大盘分析、商品榜单等功能模块的设计，充分参考了亚马逊成熟的选品经验，并且结合TikTok作为社媒内容营销的平台特性，从达人、素材方面也提供更具体实战价值的数据参考。对于原亚马逊美区卖家，可以从Glodastory的行业大盘分析上整体了解细分市场的竞争，找准赛道，再通过爆品、低粉爆款内容等榜单找到市场切入口。
                    </div>
                  </div>
                  <div class="flex items-center gap-[3.125vw] pt-20px">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-1-1.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        分析市场大盘竞争
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-1-2.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        榜单挖掘潜在爆品
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-1-3.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        研究TikTok内容营销打法
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="guide__carousel-box">
                    <div class="guide__carousel-top relative flex gap-14px color-[var(--color-1)]">
                      <img class="h-10.41vw" src="@/assets/img/example-2.png" alt="tiktok卖家">
                      <div class="pr-2.083vw pt-2.083vw text-0.9475vw leading-[1.979vw]">
                        TikTok目前的增长趋势很接近2020年前后的抖音商业化进度。从2020年以来到今天，我们看到国内抖音的巨大增速，也许就是TikTok的下一个5年蓝海机会。Glodastory团队有着非常丰富的抖音数据分析运营经验，能从国内很多的营销方法论上，找到TikTok还没被开发的增长点。
                      </div>
                      <div class="absolute bottom-1.041vw right-40px text-[1.041vw]">
                        ——原抖音卖家
                      </div>
                    </div>
                    <div class="guide__carousel-bottom">
                      Glodastory行业首创跨平台创意对比分析，就是要帮助用户通过国内抖音非常成熟的经验，挖掘TikTok在内容营销上可被复制的创意。和大多数货架电商逻辑不太一样，TikTok还是以兴趣电商为主，而在抖音上无数优秀的创作者沉淀下来的经验和案例，绝对是兴趣电商增长的优秀典范。对于原来比较熟悉抖音的卖家和创作者来说，Glodastory的数据分析方法论和跨平台找创意的思路非常符合他们的思考习惯。
                    </div>
                  </div>
                  <div class="flex items-center gap-[3.125vw] pt-20px">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-2-1.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        跨平台创意对比分析
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-2-2.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        挖掘符合TikTok的创意
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-2-3.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        借鉴参考，创作内容
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="guide__carousel-box">
                    <div class="guide__carousel-top relative flex gap-14px color-[var(--color-1)]">
                      <img class="h-10.41vw" src="@/assets/img/example-3.png" alt="tiktok商品">
                      <div class="pr-2.083vw pt-2.083vw text-0.9475vw leading-[1.979vw]">
                        TikTok美区70%以上的GMV来自达人带货，而达人带货有80%GMV来自腰部达人。然而，海外达人和国内商家天然存在文化、语音、习惯等差异。如何高效建联达人，矩阵式带货，是TikTok致胜的核心关键。
                      </div>
                      <div class="absolute bottom-1.041vw right-40px text-[1.041vw]">
                        ——MCN/TAP机构
                      </div>
                    </div>
                    <div class="guide__carousel-bottom">
                      除了联盟推广的达人，Glodastory还通过AI算法，高频监控具有潜力的内容达人，覆盖非常大量的长尾达人，并通过内部自研的价值评估算法，对达人的成长潜力评估打分。可以帮助MCN和TAP机构，更及时洞察到潜力达人，更早地建联达成信任基础，并且基于大批量的长尾KOC，对货盘带动效果更明显。
                    </div>
                  </div>
                  <div class="flex items-center gap-[3.125vw] pt-20px">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-3-1.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        挖掘TikTok增长潜力达人
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-3-2.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        先于其他商家提前建联
                      </div>
                    </div>
                    <img class="h-2.60vw w-2.60vw" src="@/assets/img/example-jt.svg" alt="">
                    <div class="w-0 flex flex-1 flex-col items-center justify-center">
                      <img class="w-4.166vw" src="@/assets/img/example-icon-3-3.svg" alt="">
                      <div class="pt-12px text-[1.041vw] color-[var(--color-1)] font-500">
                        长期追踪培养，形成矩阵资源
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>

        <!-- page5 -->
        <div class="box-border h-[100vh] w-full overflow-hidden pt-64px">
          <div class="box-border h-full flex flex-col items-center justify-center pb-70px">
            <h3 text-1.77vw font-600>
              TikTok资讯
            </h3>
            <h4 text-0.83vw font-400>
              无限续最新资讯政策、运营干货、营销玩法、行业研报...
            </h4>
            <ul mt-2.08vw w-67.7vw flex flex-wrap justify-between gap-y-1.04vw>
              <li
                v-for="article in articleList" :key="article.id"
                class="article-item"
                h-15.3vw w-15.9vw cursor-pointer overflow-hidden rounded-1.14vw bg-white p-0.2vw
                transition-transform-500 shadow-1
                @click="$router.push(`/content/detail/${article.id}`)"
              >
                <div h-9.11vw w-full overflow-hidden rounded-0.987vw>
                  <img
                    m-auto h-full w-full object-c transition-transform-500
                    :src="article.cdnHost + article.navigateImgUrl" alt=""
                  >
                </div>
                <h1 m-auto mt-1.01vw h-2.6vw w-13.9vw text-0.937vw font-500 line-height-1.25vw ellipsis-2>
                  {{ article.title }}
                </h1>
                <p m-auto mt-0.38vw w-13.9vw text-0.729vw font-500 line-height-1.04vw class="text-[var(--color-grey-4)]">
                  {{ article.createTime }}
                </p>
              </li>
            </ul>
            <button
              class="hover mt-1.25vw flex cursor-pointer items-center gap-10px rounded-90px bg-[var(--color-1)] px-1.354vw py-0.468vw text-[0.833vw] color-[var(--bright-color)]"
              @click="$router.push('/content')"
            >
              更多资讯
              <img src="@/assets/img/jt-icon.svg" title="更多资讯" class="w-1.302vw" alt="更多资讯">
            </button>
          </div>
        </div>

        <!-- page6 -->
        <div class="relative box-border h-[100vh] w-full overflow-hidden pt-60px">
          <div class="h-full flex flex-col items-center justify-center">
            <div class="pb-2.604vw text-30px color-[var(--color-1)] font-600">
              合作方寄语
            </div>
            <div class="guide__carousel guide__carousel-cooperation pb-17.39vw">
              <el-carousel indicator-position="outside" arrow="always" :autoplay="false">
                <el-carousel-item>
                  <div class="guide__carousel-cooperation-box flex items-center color-[var(--color-1)]">
                    <img class="ml-2.5vw w-5.20vw overflow-hidden rounded-2/4" src="@/assets/img/cooperation-gh.png" alt="杨国辉">
                    <div class="pl-0.833vw text-0.729vw">
                      <div class="pb-6px text-1.56vw">
                        杨国辉
                      </div>
                      <span>航海加业务负责人</span>
                    </div>
                    <div class="ml-24px box-border h-full w-0 flex flex-1 gap-18px rounded-50vw bg-[var(--bright-color)] px-2.604vw py-1.56vw text-16px leading-30px">
                      <img class="h-1.458vw w-1.458vw" src="@/assets/img/cooperation-icon.svg" alt="">
                      <div class="w-0 flex-1">
                        建议商家先关注平台，再关注平台上的细分市场，再到具体的选品和测品策略。
                        Glodastory的市场研究模块能够帮助商家很好地先了解细分市场的整体情况，判断市场的主要竞争对手，再拟定货盘和内容策略。
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="guide__carousel-cooperation-box flex items-center color-[var(--color-1)]">
                    <img class="ml-2.5vw w-5.20vw overflow-hidden rounded-2/4" src="@/assets/img/cooperation-avatar.png" alt="张天佳">
                    <div class="pl-0.833vw text-0.729vw">
                      <div class="pb-6px text-1.56vw">
                        张天佳
                      </div>
                      <span>鬼手剪辑创始人</span>
                    </div>
                    <div class="ml-24px box-border h-full w-0 flex flex-1 gap-18px rounded-50vw bg-[var(--bright-color)] px-2.604vw py-1.56vw text-16px leading-30px">
                      <img class="h-1.458vw w-1.458vw" src="@/assets/img/cooperation-icon.svg" alt="">
                      <div class="w-0 flex-1">
                        Glodastory提供了非常优质的电商创意洞察服务，能创新的通过大数据对比挖掘全球TikTok和国内抖音的素材异同，再结合鬼手剪辑AI剪辑工具，批量生成测款视频，是数据洞察-选品测款-投放爆发的终极武器。
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

          <div class="absolute bottom-0 left-0 w-full bg-[var(--color-1)]">
            <Footer theme="dark" m-x-auto />
          </div>
        </div>
      </div>
    </div>
    <div v-if="scrollParams.page !== 1" class="guide__backtop" @click="goTop">
      <i class="ds-icon-down7" title="回到顶部" />
    </div>
    <!-- <div v-if="![1, 5].includes(scrollParams.page) && isShowFix && !isLoginIn" class="guide__fix-bot flex items-center justify-center">
      产品上线公测期，最高免费使用90天（3.1-5.30）
      <div class="font-500 flex items-center gap-10px h-48px px-26px rounded-90px bg-[var(--color-1)] cursor-pointer text-18px color-[var(--bright-color)] hover" @click="showLogin()">
        马上领取 TikTok 增长大礼包
        <img src="@/assets/guide/jt-icon.svg" title="马上领取 TikTok 增长大礼包" class="w-25px" alt="">
      </div>
      <div class="close">
        <i class="ds-icon-close-line" title="关闭TikTok 增长大礼包" @click="closeFix" />
      </div>
    </div> -->
    <div v-if="scrollParams.page > 1" class="fixed right-40px top-100px z-99">
      <div class="relative flex flex-col gap-32px pl-20px">
        <div class="absolute bottom-10px left-0 top-10px w-1px bg-black opacity-10" />
        <div class="absolute z-3 h-20px w-4px rounded-8px bg-[var(--color-1)] transition-all duration-600 -left-2px" :style="{ transform: `translateY(${(scrollParams.page - 2) * 52}px)` }" />
        <div
          v-for="(item, index) in sideBarData"
          :key="index"
          class="relative cursor-pointer text-14px color-[var(--color-1-5)] font-500 leading-20px hover:color-[var(--color-1)]"
          :class="{ 'side-bar-active': scrollParams.page === item.page }"
          @click="onSideBar(item)"
        >
          {{ item.name }}
          <div class="absolute left--20px top-1/2 h-4 w-4 border border-[var(--color-1-5)] rounded-1/2 border-solid bg-[var(--color-2-10)] -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.guide {
  background-size: cover;
  .fiexd-box {
    width: 100%;
    position: sticky;
    background-size: cover;
    top: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  &__page-1-open {
    width: 5vw;
    position: absolute;
    top: -1.041vw;
    left: 0;
    transform: rotate(-17.871deg);
    animation: spin 1.3s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(-17.871deg);
    }
    50% {
      transform: rotate(-12.871deg);
    }
    to {
      transform: rotate(-17.871deg);
    }
  }
  &__fix-bot {
    width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    position: fixed;
    background: url('@/assets/img/fix-bg.jpg') center center no-repeat;
    background-size: cover;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 20px;
    font-size: 24px;
    color: var(--bright-color);
    font-weight: 600;
    gap: 32px;
    .close {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      background: rgba(20, 10, 44, 0.4);
      cursor: pointer;
      position: absolute;
      right: 32px;
      top: 50%;
      margin-top: -20px;
      transition: all 0.3s;
      &:hover {
        background: rgba(20, 10, 44, 0.7);
      }
    }
  }
  &__backtop {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bright-color);
    background-color: var(--color-1);
    cursor: pointer;
    transform: rotate(180deg);
    font-size: 24px;
    border-radius: 50%;
    position: fixed;
    right: 32px;
    bottom: 100px;
    z-index: 99;
    &:hover {
      background: var(--hover-bg-color);
    }
  }
  &__carousel {
    width: 1132px;
    &-box {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1019607843);
      border-radius: 40px;
      overflow: hidden;
    }
    :deep(.el-carousel--horizontal) {
      overflow: inherit;
    }
    :deep(.el-carousel__container) {
      overflow: hidden;
      height: 27.08vw;
      border-radius: 40px;
    }

    :deep(.el-carousel__arrow--left) {
      left: -84px;
    }
    :deep(.el-carousel__arrow--right) {
      right: -84px;
    }
    :deep(.el-carousel__arrow) {
      width: 50px;
      height: 50px;
      background-color: var(--bright-color);
      box-shadow: 0 0 30px 0 #0000001a;
    }
    :deep(.el-carousel__arrow i) {
      color: var(--color-1);
      width: 20px;
      height: 20px;
    }
    :deep(.el-carousel__arrow:hover) {
      background: var(--hover-bg-color);
      i {
        color: var(--bright-color);
      }
    }
    :deep(.el-icon svg) {
      width: 20px;
      height: 20px;
    }
    :deep(.el-carousel__button) {
      width: 40px;
      height: 4px;
      border-radius: 8px;
    }
    :deep(.el-carousel__indicators--outside button) {
      background-color: var(--color-1);
    }
    &-top {
      width: 100%;
      height: 10.41vw;
      border-radius: 40px 40px 0 0;
      overflow: hidden;
      background: url('@/assets/img/example-bg.jpg') center center no-repeat;
      background-size: cover;
      color: var(--color-1);
    }
    &-bottom {
      width: 100%;
      border-radius: 0 0 40px 40px;
      padding: 1.666vw 2.0833vw;
      box-sizing: border-box;
      font-size: 14px;
      color: var(--color-1);
      line-height: 22px;
      background: var(--bright-color);
    }
  }
  &__carousel-cooperation {
    width: 1132px;
    &-box {
      height: 16vh;
      width: 100%;
      background: url('@/assets/img/cooperation-bg.png') center center no-repeat;
      background-size: cover;
      border-radius: 50vw;
      overflow: hidden;
    }
    :deep(.el-carousel__container) {
      height: 16vh;
      border-radius: 50vw;
    }
    :deep(.el-carousel__indicators--outside) {
      display: none;
    }
  }
  &__page-animate-box {
    box-shadow: 0 0 30px 0 #0000001a;
    background: var(--bright-color);
    padding: 1.044vw 1.701vw;
    display: inline-block;
    font-size: 1.044vw;
    border-radius: 100px;
    position: absolute;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.3s;

    .text {
      background: var(--hover-bg-color);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
      font-weight: 500;
    }
    &.page2-animate1 {
      left: -10.12vw;
      bottom: 3.825vw;
      transform: translate(0, 300%);
      opacity: 0;
    }
    &.page2-animate2 {
      left: 12.645vw;
      bottom: -3px;
      transform: translate(0, 300%);
      opacity: 0;
      transition: all 1s ease-in-out;
    }
    &.page2-animate3 {
      right: -11.12vw;
      bottom: 4.288vw;
      transform: translate(0, 350%);
      opacity: 0;
      transition: all 1.2s ease-in-out;
    }
    &.page3-animate1 {
      left: -14.12vw;
      bottom: 12.68vw;
      transform: translate(0, 300%);
      opacity: 0;
    }
    &.page3-animate2 {
      right: 4.24vw;
      top: 0;
      transform: translate(0, 300%);
      opacity: 0;
      transition: all 1s ease-in-out;
    }
    &.page3-animate3 {
      right: -10.32vw;
      bottom: 1.05vw;
      transform: translate(0, 350%);
      opacity: 0;
      transition: all 1.2s ease-in-out;
    }
  }
  .page-transition {
    .guide__page-animate-box {
      &.page2-animate1 {
        transform: translate(0, 0);
        opacity: 1;
      }
      &.page2-animate2 {
        transform: translate(0, 0);
        opacity: 1;
      }
      &.page2-animate3 {
        transform: translate(0, 0);
        opacity: 1;
      }
      &.page3-animate1 {
        transform: translate(0, 0);
        opacity: 1;
      }
      &.page3-animate2 {
        transform: translate(0, 0);
        opacity: 1;
      }
      &.page3-animate3 {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
  .side-bar-active {
    color: var(--color-1);
  }

  .article-item {
    &:hover {
      transform: translateY(-6px);

      img {
        transform: scale(1.1);
      }

      h1 {
        color: var(--color-2);
      }
    }
  }
}
</style>
