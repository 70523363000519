<template>
  <div class="absolute left-0 top-0 h-full min-w-1300px w-full overflow-hidden">
    <div class="guide-an4-top" />
    <div class="guide-an4-bot" />
  </div>
</template>

<style lang="scss" scoped>
.guide-an4-top {
  width: 1400px;
  height: 200%;
  background: url("@/assets/img/tag_blue_gradient.png") no-repeat left top;
  background-size: contain;
  position: absolute;
  right:-80%;
  top: -10%;
  animation: guide-an4-top-animation 14s linear 0s infinite;
}
@keyframes guide-an4-top-animation {
  0% {
    transform: translate(-50%, -50%);
  }

  25% {
    transform: translate(-28%, -20%);
  }

  // 50% {
  //   transform: translate(-20%, -10%);
  // }
  74% {
    transform: translate(-70%, -20%);
  }

  to {
    transform: translate(-50%, -50%);
  }
}

.guide-an4-bot {
  width: 1400px;
  height: 200%;
  background: url("@/assets/img/phito_green_gradient.png") no-repeat left top;
  background-size: contain;
  position: absolute;
  bottom: -600px;
  left: -525px;
  animation: guide-an4-bot-animation 10s linear 0s infinite;
}
@keyframes guide-an4-bot-animation {
  0% {
    transform: translate(-200px, 100px);
  }

  25% {
    transform: translate(100px, 130px)
  }

  50% {
    transform: translate(50px, 90px);
  }
  74% {
    transform: translate(10px, 250px);
  }

  to {
    transform: translate(-200px, 100px);
  }
}
</style>
